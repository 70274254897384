<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-toolbar-title>Фінансові звіти</v-toolbar-title>
      </v-toolbar>

      <FinancialReportFilters
        :dates="selectedDates"
        :filter-defs="defaultFilterDefs"
        @clear="clearFilter"
        @select="selectFilter"
      />

      <template>
        <v-tabs v-model="currentTab" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="(item, index) in filteredTabsWithRoles" :key="index">
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </template>
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in filteredTabsWithRoles" :key="index">
          <v-card color="blue-grey lighten-5" flat>
            <v-skeleton-loader v-if="!isChartLoaded" type="card, article"></v-skeleton-loader>
            <component :is="item.component" v-show="isChartLoaded" :currentTab="currentTab"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import FinancialReportFilters from '@/components/common/filters/FinancialReportFilters'
import ManageFiltersOptions from '@/mixins/manageFiltersOptions'
import GetTabsWithRoles from '@/mixins/getTabsWithRoles'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BackButton from '@/components/common/BackButton'
import moment from 'moment'
import { ROLE } from '@/const/roles.enum'

export default {
  name: 'FinancialReport',

  components: {
    BackButton,
    FinancialReportFilters,
    Payments: () => import('@/components/finance/financial_statement/Payments'),
    PaymentsTypes: () => import('@/components/finance/financial_statement/PaymentsTypes'),
    PaymentsStatuses: () => import('@/components/finance/financial_statement/PaymentsStatuses'),
    AverageCheck: () => import('@/components/finance/financial_statement/AverageCheck'),
    UniqueClients: () => import('@/components/finance/financial_statement/UniqueClients'),
  },

  mixins: [ManageFiltersOptions, GetTabsWithRoles],

  data: () => ({
    currentTab: null,
    tabs: [
      {
        title: 'Платежі',
        component: 'Payments',
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT],
      },
      {
        title: 'Типи платежів',
        component: 'PaymentsTypes',
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT],
      },
      {
        title: 'Статуси платежів',
        component: 'PaymentsStatuses',
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT],
      },
      {
        title: 'Середній чек',
        component: 'AverageCheck',
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT],
      },
      {
        title: 'Кількість унікальних клієнтів',
        component: 'UniqueClients',
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT],
      },
    ],
    selectedDates: [moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  }),

  computed: {
    ...mapGetters('companies', ['getBalanceHolderFilter', 'getServicePointFilter', 'getTerminalFilter', 'getDeviceFilter']),
    ...mapState('payments', ['isChartLoaded']),

    currentCompanyId() {
      return this.$route.params.id
    },
  },

  created() {
    this.initialize()
    this.createPayload()
  },

  beforeDestroy() {
    this.SET_STATISTIC_CHART_DATA_PAYMENTS({})
  },

  methods: {
    ...mapActions('companies', ['loadFilter']),
    ...mapMutations('payments', ['SET_STATISTIC_CHART_PAYLOAD', 'SET_STATISTIC_CHART_DATA_PAYMENTS']),

    async initialize() {
      await this.loadFilter(this.currentCompanyId)
    },

    createPayload() {
      const payload = {
        data: {
          'servicePoint.balanceHolder': this.selectedBalanceHolders,
          servicePoint: this.selectedServicePoints,
          terminal: this.selectedTerminals,
          device: this.selectedDevices,
          'device.type': this.selectedDevicesTypes,
          createdAt: {
            fromDate: this.selectedDates[0],
            toDate: this.selectedDates[1],
          },
        },
        companyId: this.currentCompanyId,
      }
      this.SET_STATISTIC_CHART_PAYLOAD(payload)
    },

    selectFilter(id, val) {
      this.defaultFilterDefs.find((item) => item.id === id).applyFunc(val)
      this.createPayload()
    },

    clearFilter(id) {
      this.defaultFilterDefs.find((item) => item.id === id).applyFunc([])
      this.createPayload()
    },
  },
}
</script>
